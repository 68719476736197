// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`active project index display hidden: ${CHANGE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_activeProjectIndexDisplayHidden(CHANGES) {
  if (!("activeProjectIndexDisplayHidden" in CHANGES)) return;

  // Setup...
  const { activeProjectIndexDisplayHidden } = CHANGES;
  const { activeProjectIndexDisplay } = this.ref;
  this.logger("state-change", loggerMsgs.stateChange(activeProjectIndexDisplayHidden), "default", { inline: true });

  // Toggle data-is-hidden attr. on active project index display...
  activeProjectIndexDisplay?.parentNode?.setAttribute("data-is-hidden", activeProjectIndexDisplayHidden);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
