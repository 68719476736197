// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`mode: ${CHANGE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_mode(CHANGES) {
  if (!("mode" in CHANGES)) return;

  // Setup...
  const { mode } = CHANGES;
  const { is_mobile } = this.state;
  this.logger("state-change", loggerMsgs.stateChange(mode), "default", { inline: true });

  this.setState({
    // Disable/Enable nav. buttons when switching to 'inactive'/'active' mode...
    navBtnsDisabled: mode === "inactive",

    // Disable/Enable slide dragging when switching to 'inactive'/'active' mode...
    dragDisabled: mode === "inactive" || !is_mobile,

    // Hide/Show active project index display when switching to 'inactive'/'active' mode...
    activeProjectIndexDisplayHidden: mode === "inactive",
  });

  // Hide 'next project' label, if in 'active' mode...
  if (mode === "active") this.setState({ nextProjectLabelHidden: true });

  // Hide 'view project' label, if in 'inactive' mode...
  if (mode === "inactive") this.setState({ viewProjectLabelHidden: true });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
