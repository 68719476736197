// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`cursor state:`, CHANGE],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_cursorState(CHANGES) {
  if (!("cursorState" in CHANGES)) return;

  // Setup...
  const { cursorState } = CHANGES;
  const { cursor } = this.ref;
  // this.logger("state-change", loggerMsgs.stateChange(cursorState));

  // Check for and execute position updt...
  if ("position" in cursorState) {
    cursor.style.left = `${cursorState.position.x}px`;
    cursor.style.top = `${cursorState.position.y}px`;
  }

  // Check for and execute text updt...
  if ("text" in cursorState) cursor.textContent = cursorState.text;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
