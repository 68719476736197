// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "../../../../../app/lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`current image index: ${CHANGE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_currImgIndex(CHANGES) {
  if (!("currImgIndex" in CHANGES)) return;

  // Setup...
  const { currImgIndex } = CHANGES;
  const { images, currImgIndexDisplay, imgButtons } = this.ref;
  const { is_childOfProjectSlider, parentID } = this.options;
  const { is_mobile, mode } = this.state;
  this.logger("state-change", loggerMsgs.stateChange(currImgIndex), "default", { inline: true });

  // Show img. el. corresponding to new index...
  images.forEach((img, i) => img.setAttribute("data-is-hidden", i !== currImgIndex));

  // Updt. current image index display...
  if (this.validate_refEL(currImgIndexDisplay)) currImgIndexDisplay.innerHTML = currImgIndex + 0;

  // If curr. index is greater than 0, take comp. into 'active' mode...
  this.setState({ mode: currImgIndex > 0 ? "active" : "inactive" });

  // Show/hide img. counter...
  const is_onLastImg = currImgIndex === images.length - 1;
  const is_onFirstImg = currImgIndex === 0;
  if (mode === "active") this.setState({ imgCounterHidden: (is_onLastImg && !is_mobile) || is_onFirstImg });

  // If comp. is a child of project slider, emit event to updt. slider’s mode state...
  // (project slider mode switches to 'inactive' when a child flip-show has been entered, i.e.
  // shows any img. beyond the first one, and switches back to 'active' when the first img. is shown)
  if (is_childOfProjectSlider) eventbus.emit("FlipShow_stCh_currImgIndex", { parentID, currImgIndex });

  // If comp. is a child of project slider, emit event to updt. corresp. slider’s active number btn. index...
  if (is_childOfProjectSlider) eventbus.emit("ProjectSlider.api.set_activeNumBtnIndex", { id: parentID, index: currImgIndex });

  // Toggle img. button data-is-active attr...
  imgButtons.forEach((button, i) => button.setAttribute("data-is-active", i === currImgIndex));
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
