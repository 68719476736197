// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`project meta display hidden: ${CHANGE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_projectMetaDisplayHidden(CHANGES) {
  if (!("projectMetaDisplayHidden" in CHANGES)) return;
  if (!this.validate_refEL(this.ref?.projectMetaDisplay)) return;

  // Setup...
  const { projectMetaDisplay } = this.ref;
  const { projectMetaDisplayHidden } = CHANGES;
  this.logger("state-change", loggerMsgs.stateChange(projectMetaDisplayHidden), "default", { inline: true });

  // Show/hide project meta display...
  projectMetaDisplay.setAttribute("data-is-hidden", projectMetaDisplayHidden);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
