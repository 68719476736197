// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  eventStart: ["window has been resized"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_window_resize(EVENTDATA) {
  // Setup...
  const { textFitTarget } = this.ref;
  this.logger("event", loggerMsgs.eventStart, "event", { inline: true });

  // Updt. is_mobile state...
  const is_mobile = window.innerWidth < 1024;
  this.setState({ is_mobile });

  // Guard...
  if (!this.validate_refEL(textFitTarget) || is_mobile) return;

  // Fit text to target...
  this.fitTextToTarget(textFitTarget);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
