// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`active project:`, CHANGE],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_activeProject(CHANGES) {
  if (!("activeProject" in CHANGES)) return;

  // Setup...
  const { activeProject } = CHANGES;
  const { index, title, color } = activeProject;
  const { projectTitleDisplay, activeProjectIndexDisplay, numberBtnWrapper } = this.ref;
  this.logger("state-change", loggerMsgs.stateChange(activeProject));

  // Updt. project title display...
  projectTitleDisplay.textContent = title;
  projectTitleDisplay.style.color = color;

  // Updt. active project index display...
  activeProjectIndexDisplay.textContent = index + 1;

  // Updt. number btn. color...
  numberBtnWrapper.style.color = color;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
