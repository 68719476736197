// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`drag disabled: ${CHANGE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_dragDisabled(CHANGES) {
  if (!("dragDisabled" in CHANGES)) return;

  // Setup...
  const { dragDisabled } = CHANGES;
  const flickityInst = this.modules.flickity.instance;
  this.logger("state-change", loggerMsgs.stateChange(dragDisabled), "default", { inline: true });

  // Disable/Enable slide dragging...
  flickityInst.options.draggable = !dragDisabled;
  flickityInst.updateDraggable();
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
