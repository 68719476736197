// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function ebh_swup_content_replace(ARGS) {
  // Setup...
  const { swupIntegration } = this.options;
  const { with_switchingFragments } = swupIntegration;
  const { fragmIsBeingOpened, containers, fragmentVisit, _name } = ARGS;
  this.logger("eventbus call", [_name, ARGS], "event");

  ///////////////////////////////////////////////
  // Fragment Page component loading/unloading //
  ///////////////////////////////////////////////

  if (fragmIsBeingOpened) {
    const fragmPageElID = containers[0]?.replace("#", "");
    this.load_fragmentPageComp(fragmPageElID);

    // Fragment switching //
    if (!with_switchingFragments) return;
    if (fragmentVisit?.name === "switchfragments") {
      // Unload prev. opened fragment page component...
      this.unload_fragmentPageComp("fragmentPage0");

      // Replace prev. opened fragment page comp. el. w/ <template>...
      setTimeout(() => {
        const newFragmPageEl = document.getElementById("fragmentPage1");
        const prevFragmPageEl = document.getElementById("fragmentPage0");
        const template = document.createElement("template");
        prevFragmPageEl.remove();
        document.body.appendChild(template);
        template.id = "fragmentPage1";
        newFragmPageEl.id = "fragmentPage0";
      }, 500);
    }
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default ebh_swup_content_replace;
