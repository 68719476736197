// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_mouseMove from "../eventHandlers/eh_mouseMove.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  initStart: ["init. mouseMoveHdl"],
  initSuccess: ["init. mouseMoveHdl", "init. success"],
  noValidDOMEl: ["init. mouseMoveHdl", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_mouseMoveHdl() {
  // Setup...
  this.logger("init", loggerMsgs.initStart, "action");

  // Guard...
  if (!this.validate_refEL(this.element)) return this.cancel_featureInit(loggerMsgs.noValidDOMEl);

  // Listen to mouse movem. on comp. el...
  this.element.addEventListener("mousemove", eh_mouseMove.bind(this));

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", { inline: true });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
