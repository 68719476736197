// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function enable_scroll_fully() {
  const { wheelOption, wheelEventName } = this.options;
  window.removeEventListener("DOMMouseScroll", this.preventDefault, false);
  window.removeEventListener(wheelEventName, this.preventDefault, wheelOption);
  window.removeEventListener("touchmove", this.preventDefault, wheelOption);
  window.removeEventListener("keydown", this.preventDefaultForScrollKeys, false);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
