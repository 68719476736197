// ********************************* UTILITIES ********************************** //
// ****************************************************************************** //

// ...

// ****************************************************************************** //
// ****************************************************************************** //

function set_state(CHANGES, WITH_callback = true) {
  let stateChanges = {};

  Object.keys(CHANGES).forEach((key) => {
    if (Array.isArray(CHANGES[key])) {
      if (this._state[key] != null && Array.isArray(this._state[key])) {
        if (this._state[key].length === CHANGES[key].length) {
          CHANGES[key].some((item, index) => {
            if (this._state[key][index] !== item) {
              stateChanges[key] = CHANGES[key];
              this._state[key] = stateChanges[key];
              return true;
            }
            return false;
          });
        } else {
          stateChanges[key] = CHANGES[key];
          this._state[key] = stateChanges[key];
        }
      } else {
        stateChanges[key] = CHANGES[key];
        this._state[key] = stateChanges[key];
      }
    } else if (typeof CHANGES[key] === "object") {
      if (this._state[key] != null && typeof this._state[key] === "object") {
        stateChanges[key] = {};
        Object.keys(CHANGES[key]).forEach((subkey) => {
          if (this._state[key][subkey] !== CHANGES[key][subkey]) {
            stateChanges[key][subkey] = CHANGES[key][subkey];
          }
        });
      } else {
        stateChanges[key] = CHANGES[key];
      }

      this._state[key] = {
        ...this._state[key],
        ...stateChanges[key],
      };
    } else {
      if (this._state[key] !== CHANGES[key]) {
        stateChanges[key] = CHANGES[key];

        this._state[key] = CHANGES[key];
      }
    }
  });

  Object.keys(stateChanges).forEach((key) => {
    if (Array.isArray(CHANGES[key])) {
      if (stateChanges[key].length === 0) {
        delete stateChanges[key];
      }
    } else if (typeof CHANGES[key] === "object") {
      if (Object.keys(stateChanges[key]).length === 0) {
        delete stateChanges[key];
      }
    }
  });

  if (!WITH_callback) return;
  this.stateChange(stateChanges);
}

// ****************************************************************************** //
// ****************************************************************************** //

export default set_state;
