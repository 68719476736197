// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`number button count: ${CHANGE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_numBtnCount(CHANGES) {
  if (!("numBtnCount" in CHANGES)) return;

  // Setup...
  const { numBtnCount } = CHANGES;
  const { numberBtnWrapper } = this.ref;
  this.logger("state-change", loggerMsgs.stateChange(numBtnCount), "default", { inline: true });

  // Use to updt. cursor state (updt. 2nd number in cursor fraction text)...
  this.setState({ cursorState: { ...this.state.cursorState, text: `1/${numBtnCount}` } });

  /////////////////////////
  // Number button updt. //
  /////////////////////////

  // Check existing number button count...
  const existingBtnCount = numberBtnWrapper.children.length;
  if (existingBtnCount === numBtnCount) return;

  // Temp. hide button wrapper...
  numberBtnWrapper.dataset.isHidden = true;

  // Add/remove number buttons...
  setTimeout(() => {
    // Remove all existing number buttons...
    numberBtnWrapper.innerHTML = "";

    // Add new number buttons...
    for (let i = 0; i < numBtnCount; i++) {
      const btn = `
        <button data-index    ="${i}"
                data-is-active="${i === 0}"
                class         ="leading-none
                                flex items-center justify-center
                                cursor-pointer
                                data-[is-active=true]:text-[limegreen]">
          ${i + 1}
        </button>`;
      numberBtnWrapper.insertAdjacentHTML("beforeend", btn);
    }

    // Show button wrapper...
    numberBtnWrapper.dataset.isHidden = false;
  }, 700);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
