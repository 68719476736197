// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eventbus from "./lib/jGia/jGia/src/eventbus";

// ——————————————————————————————————————————— IMPORTS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// Modules //
import JGiaAppModule from "./modules/baseAppModules/jGia";
import swupAppModule from "./modules/baseAppModules/appMod-swup";
import LazyLoaderAppModule from "./modules/baseAppModules/LazyLoader";
import WindowScroll from "./modules/baseAppModules/WindowScroll/WindowScroll";
import TextFitter from "./modules/TextFitter/TextFitter.js";

// Components //
import FlipShow from "../site/plugins/component-flip-show/js/FlipShow.js";
import ProjectSlider from "../site/plugins/component-project-slider/js/ProjectSlider.js";
import LinkList from "../site/plugins/component-link-list/js/LinkList.js";

const availableComponents = [
  // Global
  // ...

  // Local
  { type: "FlipShow", script: FlipShow },
  { type: "ProjectSlider", script: ProjectSlider },
  { type: "LinkList", script: LinkList },

  // Other
  // ...
];

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_swup_page_load from "./eventHandlers/swup/eh_swup_page_load";

// Custom event handlers for swup module //
const swupEventHandlers = [
  {
    id: "page:load",
    handler: eh_swup_page_load,
  },
];

// Custom eventbus handlers for swup module //
const swupEventbusConfig = {
  callsToAnswer: ["document_click"],
  handlers: [{ id: "document_click", handler: () => console.log("swup: document was clicked") }],
};

// ————————————————————————————————————————— MODULE INIT. ————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

(async () => {
  // Fetch app config...
  const appConfigRequest = await fetch("/app/config.json");
  const appConfig = await appConfigRequest.json();
  const giaConfig = appConfig?.gia;
  const swupConfig = appConfig?.swup;
  const lazyLoaderConfig = appConfig?.lazyLoader;
  const windowScrollConfig = appConfig?.windowScroll;
  const globalComponentConfigs = appConfig?.globalComponents;
  const localComponentConfigs = appConfig?.localComponents;
  const textFitterConfig = appConfig?.TextFitter;

  // Extend module configs...
  swupConfig.eventbus = swupEventbusConfig;
  swupConfig.eventHandlers = swupEventHandlers;
  swupConfig.useNewJGiaPlugin = true;

  // Create & collect module app module instances...
  const jGiaInst = new JGiaAppModule(giaConfig, globalComponentConfigs, { availableComponents });
  const swupInst = new swupAppModule(swupConfig, giaConfig, { availableComponents }, localComponentConfigs);
  const LazyLoaderInst = new LazyLoaderAppModule(lazyLoaderConfig);
  const WindowScrollInst = new WindowScroll(windowScrollConfig);
  const appModules = [jGiaInst, swupInst, LazyLoaderInst, WindowScrollInst];

  // Include custom modules...
  if (textFitterConfig) appModules.push(new TextFitter(textFitterConfig));

  // Init. app modules...
  try {
    console.log("%cInitialising app modules", "color: orange; font-weight: bold;");
    appModules.forEach((module) => {
      try {
        module.init();
      } catch (error) {
        console.error(`Module "${module.options.name}" failed to initialise.`);
        throw error;
      }
    });
    console.log("%cApp modules initialised", "color: green; font-weight: bold;");
  } catch (error) {
    console.error(error);
  }
})();

// ————————————————————————————————————————— GLOBAL STATE ————————————————————————————————————————— //
// ——————————————————————————————————————— (local storage ) ——————————————————————————————————————— //

// Create/Set global state object //
console.log("%cSetting global state", "color: orange; font-weight: bold;");
const globalState = { mediaIsEnabled: true };
localStorage.setItem("globalState", JSON.stringify(globalState));

// ———————————————————————————————————————— GLOBAL EVENTS ————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// import ebh_Foo_bar from "./eventbusHandlers/ebh_Foo_bar";

// Event emissions //
window.addEventListener("resize", () => eventbus.emit("window_resize", { windowWidth: window.innerWidth }));
document.documentElement.addEventListener("mouseleave", () => eventbus.emit("document_mouseLeave"));
document.documentElement.addEventListener("click" || "touchstart", (e) => eventbus.emit("document_click", { eventData: e }));

// Eventbus handling //
// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
