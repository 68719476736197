// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// const loggerMsgs = {};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function ebh_FlipShow_stCh_currImgIndex(ARGS) {
  // Setup...
  const { id } = this.options;
  const { parentID, currImgIndex, _name } = ARGS;
  this.logger("eventbus call", [_name, ARGS], "event");

  // If flip-show emmiting the event is a child of this comp.,
  // updt. the mode-state according to the curr. image index of the flip-show...
  // (project slider mode switches to 'inactive' when a child flip-show has been entered, i.e.
  // shows any img. beyond the first one, and switches back to 'active' when the first img. is shown)
  // if (id === parentID) this.setState({ mode: currImgIndex > 0 ? "inactive" : "active" });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
