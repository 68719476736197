// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function disable_scroll_fully() {
  const { wheelOption, wheelEventName } = this.options;
  window.addEventListener("DOMMouseScroll", this.preventDefault, false); // older FF
  window.addEventListener(wheelEventName, this.preventDefault, wheelOption); // modern desktop
  window.addEventListener("touchmove", this.preventDefault, wheelOption); // mobile
  window.addEventListener("keydown", this.preventDefaultForScrollKeys, false);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
