// ——————————————————————————————————————————— IMPORTS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import config from "../../lib/jGia/jGia/src/config";
import eventbus from "../../lib/jGia/jGia/src/eventbus";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import logger from "../../baseUtilities/logger";
import set_state from "../../baseUtilities/state/set_state";

import load_globalComp from "./utilities/jGia/load_globalComp";
import load_initFragmPageComp from "./utilities/jGia/load_initFragmPageComp";
import load_fragmentPageComp from "./utilities/jGia/load_fragmentPageComp";
import unload_fragmentPageComp from "./utilities/jGia/unload_fragmentPageComp";

// ————————————————————————————— EVENT/EVENTBUS/STATE CHANGE HANDLERS ————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import ebh_swup_page_load from "./eventbusHandlers/jGia/ebh_swup_page_load";
import ebh_swup_content_replace from "./eventbusHandlers/jGia/ebh_swup_content_replace";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

class JGiaAppModule {
  //////////////////////////////// Constructor /////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  constructor(
    CONFIG = {
      components: { globalComponents: [], localComponents: [], loadFragmPageComp: true },
      logs: { run_withLogs: false, logStyles: {} },
      swupIntegration: { is_enabled: true, with_switchingFragments: false },
    },
    GLOBALCOMPONENTCONFIGS = {},
    OPTIONS = { availableComponents: [] }
  ) {
    ///////////////////////// Options //////////////////////////
    ////////////////////////////////////////////////////////////

    this.options = {
      ...OPTIONS,
      name: "jGia",
      run_withLogs: CONFIG?.logs?.run_withLogs,
      logStyles: CONFIG?.logs?.logStyles,
      components: CONFIG?.components,
      globalComponentConfigs: GLOBALCOMPONENTCONFIGS,
      loadFragmPageComp: CONFIG?.components?.loadFragmPageComp,
      //////
      swupIntegration: CONFIG?.swupIntegration,
    };

    ////////////////////////// Util. ///////////////////////////
    ////////////////////////////////////////////////////////////

    this.logger = logger.bind(this);
    this.load_globalComp = load_globalComp.bind(this);
    this.load_initFragmPageComp = load_initFragmPageComp.bind(this);
    this.load_fragmentPageComp = load_fragmentPageComp.bind(this);
    this.unload_fragmentPageComp = unload_fragmentPageComp.bind(this);

    //////////////////// Eventbus listeners ////////////////////
    ////////////////////////////////////////////////////////////

    this.ebl_swup_page_load = ebh_swup_page_load.bind(this);
    this.ebl_swup_content_replace = ebh_swup_content_replace.bind(this);

    ////////////////////////// State ///////////////////////////
    ////////////////////////////////////////////////////////////

    this._state = { initialised: false };
    this.setState = set_state.bind(this);
  }

  ////////////////////////////// State management //////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  get state() {
    return this._state;
  }

  set state(state) {
    this.logger("info", ["You should not change state manually. Use setState() instead."], "warning");
    this._state = state;
  }

  ////////////////////////////////// Methods ///////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  init() {
    const { loadFragmPageComp } = this.options;
    //////
    this.logger("init", ["module"], "default", { inline: true });
    this.init_logs();
    this.init_eventbus();
    //////
    this.load_globalComp();
    if (loadFragmPageComp) if (document.querySelector(".FragmentPage")) this.load_initFragmPageComp();
    //////
    this.setState({ initialised: true }, false);
  }

  ////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////

  init_logs() {
    // Setup //
    this.logger("init", ["logs"], "default", { inline: true });
    const { run_withLogs, logStyles } = this.options;

    // Config. global component logging...
    config.set("log", false);
    config.set("log_custom", run_withLogs);
    config.set("logStyles", logStyles);
  }

  ////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////

  init_eventbus() {
    // Setup //
    this.logger("init", ["eventbus"], "default", { inline: true });

    // Handle eventbus calls emitted by swup events...
    eventbus.on("swup_page_load", this.ebl_swup_page_load);
    eventbus.on("swup_content_replace", this.ebl_swup_content_replace);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default JGiaAppModule;
