// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`active number button index: ${CHANGE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_activeNumBtnIndex(CHANGES) {
  if (!("activeNumBtnIndex" in CHANGES)) return;

  // Setup...
  const { activeNumBtnIndex } = CHANGES;
  const { numberBtnWrapper } = this.ref;
  this.logger("state-change", loggerMsgs.stateChange(activeNumBtnIndex), "default", { inline: true });

  // Toggle data-is-active attr. on number buttons...
  const numberBtns = Array.from(numberBtnWrapper.children);
  numberBtns.forEach((btn, i) => btn.setAttribute("data-is-active", i === activeNumBtnIndex));
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
