// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function determ_newImgIndex(INDEX_min, INDEX_max, DIR) {
  return DIR === "prev" ? (INDEX_min > 0 ? INDEX_min - 1 : INDEX_max - 1) : INDEX_min < INDEX_max - 1 ? INDEX_min + 1 : 0;
}

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  eventStart: ["nav-button was clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_navBtn_click(BUTTONEL, EVENTDATA) {
  // Setup...
  const { images } = this.ref;
  const { currImgIndex } = this.state;
  this.logger("event", loggerMsgs.eventStart, "event", { inline: true });

  // Updt. current image index according to direction attr. of clicked button...
  const maxImageCount = images.length;
  const { direction } = BUTTONEL.dataset;
  const imgIndex_new = determ_newImgIndex(currImgIndex, maxImageCount, direction);
  this.setState({ currImgIndex: imgIndex_new });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
