// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_item_mouseLeave from "../eventHandlers/eh_item_mouseLeave.js";
import eh_item_mouseEnter from "../eventHandlers/eh_item_mouseEnter.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  initStart: ["init. items"],
  initSuccess: ["init. items", "init. success"],
  noValidDOMEl: ["init. items", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_items() {
  // Setup...
  const { items } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action");

  // Guard...
  if (items.every((el) => !this.validate_refEL(el))) return this.cancel_featureInit(loggerMsgs.noValidDOMEl);

  // Filter items w/ data-show-img set to false...
  const itemWithImg = items.filter((item) => item.dataset.showImg === "true");

  // Listen to mouse events on items...
  itemWithImg.forEach((itemEl) => {
    itemEl.addEventListener("mouseenter", eh_item_mouseEnter.bind(this, itemEl));
    itemEl.addEventListener("mouseleave", eh_item_mouseLeave.bind(this, itemEl));
  });

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", { inline: true });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
