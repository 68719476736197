// ——————————————————————————————————————————— IMPORTS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import logger from "../../baseUtilities/logger.js";
import validate_refEL from "../../baseUtilities/validate/validate_refEl.js";
import cancel_featureInit from "../../baseUtilities/cancel/cancel_featureInit.js";
import set_state from "../../baseUtilities/state/set_state.js";
import fitTextToTarget from "./util/fitTextToTarget.js";

// —————————————————————————————————————— INITIALIZATION F() —————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import init_windowEvents from "./init/init_windowEvents.js";

// ————————————————————————————— EVENT/EVENTBUS/STATE CHANGE HANDLERS ————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default class TextFitter {
  //////////////////////////////// Constructor /////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  constructor(
    CONFIG = {
      logs: {
        run_withLogs: true,
        logStyles: {
          default: "#4361BC",
          action: "#c7d0ff",
          event: "#97a5ce",
          warning: "#ffaf00",
          error: "#ff3232",
          success: "#00ff00",
        },
      },
    },
    OPTIONS
  ) {
    ////////////////////// DOM references //////////////////////
    ////////////////////////////////////////////////////////////

    this.ref = {
      textFitTarget: document.querySelector("[data-fit-text-target]"),
    };

    ///////////////////////// Options //////////////////////////
    ////////////////////////////////////////////////////////////

    this.options = {
      ...OPTIONS,
      name: "TextFitter",
      run_withLogs: CONFIG?.logs?.run_withLogs,
      logStyles: CONFIG?.logs?.logStyles,
    };

    ////////////////////////// State ///////////////////////////
    ////////////////////////////////////////////////////////////

    this._state = { initialised: false };
    this.setState = set_state.bind(this);

    ////////////////////////// Util. ///////////////////////////
    ////////////////////////////////////////////////////////////

    this.logger = logger.bind(this);
    this.validate_refEL = validate_refEL.bind(this);
    this.cancel_featureInit = cancel_featureInit.bind(this);
    this.fitTextToTarget = fitTextToTarget.bind(this);

    /////////////////////// Init. f() //////////////////////////
    ////////////////////////////////////////////////////////////

    this.init_windowEvents = init_windowEvents.bind(this);

    ///////////////////////// Modules //////////////////////////
    ////////////////////////////////////////////////////////////

    // this.modules = {};

    //////////////////// Eventbus listeners ////////////////////
    ////////////////////////////////////////////////////////////

    // ...

    ////////////////// State-change listeners //////////////////
    ////////////////////////////////////////////////////////////

    // ...
  }

  ///////////////////////////////// Init. ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init() {
    this.logger("init", ["module"], "default", { inline: true });
    this.init_windowEvents();
    this.init_state();
    this.init_eventbus();
    //////
    if (!this.state.is_mobile) this.fitTextToTarget(this.ref.textFitTarget);
    //////
    this.setState({ mode: "ready", initialised: true });
  }

  ////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init_state() {
    this.logger("init", ["state"], "default", { inline: true });
    this.setState({
      mode: "initialising",
      is_mobile: window.innerWidth <= 1024,
    });
  }

  ////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init_eventbus() {
    this.logger("init", ["eventbus"], "action", { inline: true });

    ///////////////////////////
    // Listener registration //
    ///////////////////////////

    // ...
  }

  ////////////////////////////// State management //////////////////////////////
  //////////////////////////////////////////////////////////////////////////////

  get state() {
    return this._state;
  }

  set state(state) {
    this.logger("info", ["You should not change state manually. Use setState instead."], "warning");
    this._state = state;
  }

  stateChange(CHANGES) {
    //////// backgroundColor /////////
    //////////////////////////////////

    if ("backgroundColor" in CHANGES) this.stChL_backgroundColor(CHANGES);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
