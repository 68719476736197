// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`img. counter hidden: ${CHANGE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_imgCounterHidden(CHANGES) {
  if (!("imgCounterHidden" in CHANGES)) return;
  if (!this.validate_refEL(this.ref.imgCounter)) return;

  // Setup...
  const { imgCounterHidden } = CHANGES;
  const { imgCounter } = this.ref;
  this.logger("state-change", loggerMsgs.stateChange(imgCounterHidden), "default", { inline: true });

  // Show/hide img. counter...
  imgCounter.setAttribute("data-is-hidden", imgCounterHidden);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
