// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`img. button hidden: ${CHANGE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_imgButtonsHidden(CHANGES) {
  if (!("imgButtonsHidden" in CHANGES)) return;

  // Setup...
  const { imgButtonsHidden } = CHANGES;
  const { footer } = this.ref;
  this.logger("state-change", loggerMsgs.stateChange(imgButtonsHidden), "default", { inline: true });

  // Toggle data-is-hidden attr. on footer...
  footer.setAttribute("data-is-hidden", imgButtonsHidden);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
