// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// function determ_newActiveProjectIndex(INDEX_min, INDEX_max, DIR) {
//   return DIR === "prev" ? (INDEX_min > 0 ? INDEX_min - 1 : INDEX_max - 1) : INDEX_min < INDEX_max - 1 ? INDEX_min + 1 : 0;
// }

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  eventStart: ["nav-button was clicked"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_navBtn_click(BUTTONEL) {
  // Setup...
  const flickityInstance = this.modules.flickity.instance;
  this.logger("event", loggerMsgs.eventStart, "event", { inline: true });

  // Change slide...
  const { direction } = BUTTONEL.dataset;
  if (direction === "prev") flickityInstance.previous(true, true);
  else if (direction === "next") flickityInstance.next(true, true);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
