// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function convert_stringToHTML(STRING) {
  var parser = new DOMParser();
  var newHTMLdoc = parser.parseFromString(STRING, "text/html");
  return newHTMLdoc.body;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default convert_stringToHTML;
