// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import Component from "../../../../app/lib/jGia/jGia/src/Component.js";
import eventbus from "../../../../app/lib/jGia/jGia/src/eventbus.js";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import logger from "../../../../app/baseUtilities/logger.js";
import validate_refEL from "../../../../app/baseUtilities/validate/validate_refEl.js";
import cancel_featureInit from "../../../../app/baseUtilities/cancel/cancel_featureInit.js";
import cancel_ebh from "../../../../app/baseUtilities/cancel/cancel_ebh.js";

// —————————————————————————————————————— INITIALIZATION F() —————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import init_flickity from "./init/init_flickity.js";
import init_controls from "./init/init_controls.js";
import init_mouseMoveHdl from "./init/init_mouseMoveHdl.js";
import init_height from "./init/init_height.js";
import init_cursor from "./init/init_cursor.js";

// ————————————————————————————— EVENT/EVENTBUS/STATE CHANGE HANDLERS ————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import ebh_window_resize from "./eventbusHandlers/ebh_window_resize.js";
import ebh_FlipShow_stCh_currImgIndex from "./eventbusHandlers/ebh_FlipShow_stCh_currImgIndex.js";
import ebh_windowScroll_stCh_scrollPosition from "./eventbusHandlers/ebh_windowScroll_stCh_scrollPosition.js";

import stChH_mode from "./stateChangeHandlers/stChH_mode.js";
import stChH_viewProjectLabelHidden from "./stateChangeHandlers/stChH_viewProjectLabelHidden.js";
import stChH_nextProjectLabelHidden from "./stateChangeHandlers/stChH_nextProjectLabelHidden.js";
import stChH_activeProjectIndexDisplayHidden from "./stateChangeHandlers/stChH_activeProjectIndexDisplayHidden.js";
import stChH_navBtnsDisabled from "./stateChangeHandlers/stChH_navBtnsDisabled.js";
import stChH_dragDisabled from "./stateChangeHandlers/stChH_dragDisabled.js";
import stChH_height from "./stateChangeHandlers/stChH_height.js";
import stChH_activeProject from "./stateChangeHandlers/stChH_activeProject.js";
import stChH_activeNumBtnIndex from "./stateChangeHandlers/stChH_activeNumBtnIndex.js";
import stChH_numBtnCount from "./stateChangeHandlers/stChH_numBtnCount.js";
import stChH_numBtnsHidden from "./stateChangeHandlers/stChH_numBtnsHidden.js";
import stChH_cursorState from "./stateChangeHandlers/stChH_cursorState.js";
import stChH_cursorPosition from "./stateChangeHandlers/stChH_cursorPosition.js";

import set_activeNumBtnIndex from "./api/set_activeNumBtnIndex.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// Options with case-sensitive keys that are not to be
// automatically extracted from the options arg. but be
// assigned to an option key manually to preserve its case
// (Kirby CMS converts all fields/option keys to lowercase)
// (see constructor).

const manualOptionKeys = ["optionkey"];

//  Default values for manually extracted options
//  (see constructor, in case specific option has not been provided
//  in comp. config.).

const defaultOptions = {
  optionkey: { foo: "bar" },
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

class ProjectSlider extends Component {
  ///////////////////////////// Constructor //////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  constructor(element, options) {
    super(element);

    ///////// DOM references //////////
    ///////////////////////////////////

    this.ref = {
      slidesWrapper: null,
      slides: [],
      prevBtn: null,
      nextBtn: null,
      activeProjectIndexDisplay: null,
      projectTitleDisplay: null,
      viewProjectLabel: null,
      nextProjectLabel: null,
      numberBtnWrapper: null,
      cursor: null,
    };

    //////////// Options /////////////
    //////////////////////////////////

    // Get options not to be manually extracted from the options arg...
    const autoOptions = {};
    for (const key in options) if (!manualOptionKeys.includes(key)) autoOptions[key] = options[key];

    this.options = {
      name: "ProjectSlider",
      version: element.getAttribute("g-version") ?? "1",
      // optionKey: options.optionkey,
      ...autoOptions,
    };

    //////////// Utilities /////////////
    ////////////////////////////////////

    this.logger = logger.bind(this);
    this.validate_refEL = validate_refEL.bind(this);
    this.cancel_featureInit = cancel_featureInit.bind(this);
    this.cancel_ebh = cancel_ebh.bind(this);

    //////////// Init. f() /////////////
    ////////////////////////////////////

    this.init_cursor = init_cursor.bind(this);
    this.init_height = init_height.bind(this);
    this.init_flickity = init_flickity.bind(this);
    this.init_controls = init_controls.bind(this);
    this.init_mouseMoveHdl = init_mouseMoveHdl.bind(this);

    ///////////// Modules //////////////
    ////////////////////////////////////

    this.modules = {
      flickity: { instance: this.init_flickity() },
    };

    /////////////// API ////////////////
    ////////////////////////////////////

    this.api = {
      set_activeNumBtnIndex: set_activeNumBtnIndex.bind(this),
    };

    //////// Eventbus listeners ////////
    ////////////////////////////////////

    this.ebl_windowScroll_stCh_scrollPosition = ebh_windowScroll_stCh_scrollPosition.bind(this);
    this.ebl_FlipShow_stCh_currImgIndex = ebh_FlipShow_stCh_currImgIndex.bind(this);
    this.ebl_window_resize = ebh_window_resize.bind(this);

    ////// State-change listeners //////
    ////////////////////////////////////

    this.stChL_cursorState = stChH_cursorState.bind(this);
    this.stChL_cursorPosition = stChH_cursorPosition.bind(this);
    this.stChL_numBtnsHidden = stChH_numBtnsHidden.bind(this);
    this.stChL_numBtnCount = stChH_numBtnCount.bind(this);
    this.stChL_activeNumBtnIndex = stChH_activeNumBtnIndex.bind(this);
    this.stChL_height = stChH_height.bind(this);
    this.stChL_activeProject = stChH_activeProject.bind(this);
    this.stChL_dragDisabled = stChH_dragDisabled.bind(this);
    this.stChL_activeProjectIndexDisplayHidden = stChH_activeProjectIndexDisplayHidden.bind(this);
    this.stChL_mode = stChH_mode.bind(this);
    this.stChL_viewProjectLabelHidden = stChH_viewProjectLabelHidden.bind(this);
    this.stChL_nextProjectLabelHidden = stChH_nextProjectLabelHidden.bind(this);
    this.stChL_navBtnsDisabled = stChH_navBtnsDisabled.bind(this);

    ////// Custom event handlers ///////
    // (To be passed to parent class) //

    ///////// Pre-mount init. //////////
    ////////////////////////////////////

    // ...
  }

  //////////////////////////////// Mount /////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  mount() {
    this.logger("info", ["mounting"], "action", { inline: true });
    this.init();
  }

  /////////////////////////////// Unmount ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  unmount() {
    this.logger("info", ["unmounting"], "action", { inline: true });

    /////////////////////////////
    // Listener deregistration //
    /////////////////////////////

    eventbus.off("window_resize", this.ebl_window_resize);
    eventbus.off("FlipShow_stCh_currImgIndex", this.ebl_FlipShow_stCh_currImgIndex);

    /////////////////////////////
    // API call deregistration //
    /////////////////////////////

    eventbus.off("ProjectSlider.api.set_activeNumBtnIndex", this.api.set_activeNumBtnIndex);
  }

  ///////////////////////////////// Init. ////////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  init() {
    // this.init_cursor();
    this.init_height();
    this.init_controls();
    this.init_mouseMoveHdl();
    this.init_states();
    this.init_eventbus();
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_states() {
    this.logger("init", ["states"], "action", { inline: true });
    this.setState({
      is_mobile: window.innerWidth < 640,
      activeProjectIndex: 0,
      mode: "active",
    });
  }

  ////////////////////////////////////
  ////////////////////////////////////

  init_eventbus() {
    eventbus.on("windowScroll_stCh_scrollPosition", this.ebl_windowScroll_stCh_scrollPosition);
    this.logger("init", ["eventbus"], "action", { inline: true });

    ///////////////////////////
    // Listener registration //
    ///////////////////////////

    eventbus.on("window_resize", this.ebl_window_resize);
    eventbus.on("FlipShow_stCh_currImgIndex", this.ebl_FlipShow_stCh_currImgIndex);

    ///////////////////////////
    // API call registration //
    ///////////////////////////

    eventbus.on("ProjectSlider.api.set_activeNumBtnIndex", this.api.set_activeNumBtnIndex);
  }

  //////////////////////////// State Changes /////////////////////////////
  ////////////////////////////////////////////////////////////////////////

  stateChange(CHANGES) {
    /////////// cursorState ///////////
    ////////////////////////////////////

    if ("cursorState" in CHANGES) this.stChL_cursorState(CHANGES);

    ////////// cursorPosition //////////
    ////////////////////////////////////

    if ("cursorPosition" in CHANGES) this.stChL_cursorPosition(CHANGES);

    ////////// numBtnsHidden //////////
    ////////////////////////////////////

    if ("numBtnsHidden" in CHANGES) this.stChL_numBtnsHidden(CHANGES);

    /////////// numBtnCount ///////////
    ////////////////////////////////////

    if ("numBtnCount" in CHANGES) this.stChL_numBtnCount(CHANGES);

    //////// activeNumBtnIndex ////////
    ////////////////////////////////////

    if ("activeNumBtnIndex" in CHANGES) this.stChL_activeNumBtnIndex(CHANGES);

    ////////////// height //////////////
    ////////////////////////////////////

    if ("height" in CHANGES) this.stChL_height(CHANGES);

    /////////// dragDisabled ///////////
    ////////////////////////////////////

    if ("dragDisabled" in CHANGES) this.stChL_dragDisabled(CHANGES);

    /////////////////////////////////////
    // activeProjectIndexDisplayHidden //
    /////////////////////////////////////

    if ("activeProjectIndexDisplayHidden" in CHANGES) this.stChL_activeProjectIndexDisplayHidden(CHANGES);

    ///////// navBtnsDisabled /////////
    ////////////////////////////////////

    if ("navBtnsDisabled" in CHANGES) this.stChL_navBtnsDisabled(CHANGES);

    /////////////// mode ///////////////
    ////////////////////////////////////

    if ("mode" in CHANGES) this.stChL_mode(CHANGES);

    ////// viewProjectLabelHidden //////
    ////////////////////////////////////

    if ("viewProjectLabelHidden" in CHANGES) this.stChL_viewProjectLabelHidden(CHANGES);

    ////////// activeProject //////////
    ////////////////////////////////////

    if ("activeProject" in CHANGES) this.stChL_activeProject(CHANGES);
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default ProjectSlider;
