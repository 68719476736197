function toggle_class(EL, CLASSNAMES, BLOCKNAME, BOOL) {
  ////////////////////////////////////
  // Toggle multiple class names... //
  ////////////////////////////////////

  if (CLASSNAMES.constructor === Array) {
    for (let cln of CLASSNAMES) {
      if (BOOL) {
        EL.classList.add(BLOCKNAME ? BLOCKNAME + cln : cln);
      } else {
        EL.classList.remove(BLOCKNAME ? BLOCKNAME + cln : cln);
      }
    }
  } else {
    /////////////////////////////////
    // Toggle single class name... //
    /////////////////////////////////

    if (BOOL) {
      EL.classList.add(BLOCKNAME ? BLOCKNAME + CLASSNAMES : CLASSNAMES);
    } else {
      EL.classList.remove(BLOCKNAME ? BLOCKNAME + CLASSNAMES : CLASSNAMES);
    }
  }
}

////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////

export default toggle_class;
