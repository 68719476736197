// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import removeComponents from "../../../../lib/jGia/jGia/src/removeComponents";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function unload_fragmentPageComp(ID) {
  // Setup //
  this.logger("info", ["Unloading FragmentPage component..."]);

  // Check if applicable DOM element exists...
  const domEl = document.getElementById(ID);
  if (!domEl) {
    this.logger("warning", [`No DOM element found with ID ${ID}`], "warning", { inline: true });
    return;
  }

  // Unload component...
  removeComponents(null, domEl.id);
  //               ^^^^
  //               ↑ null passed for 'context' param., comp. el. is found by ID
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default unload_fragmentPageComp;
