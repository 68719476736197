// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import loadComponents from "../../../../lib/jGia/jGia/src/loadComponents";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function load_initFragmPageComp() {
  // Setup...
  const { availableComponents } = this.options;
  const context = document.querySelector(".FragmentPage");
  this.logger("info", ["loading init. fragment page components"], "action", { inline: true });

  // Format components in shape expected by loadComponents()...
  const components = availableComponents.reduce((obj, comp) => ((obj[comp.type] = comp.script), obj), {});

  // Load components...
  loadComponents(components, context);
  this.load_fragmentPageComp("fragmentPage0");
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default load_initFragmPageComp;
