// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`nav. buttons disabled: ${CHANGE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_navBtnsDisabled(CHANGES) {
  if (!("navBtnsDisabled" in CHANGES)) return;

  // Setup...
  const { navBtnsDisabled } = CHANGES;
  const { prevBtn, nextBtn } = this.ref;
  this.logger("state-change", loggerMsgs.stateChange(navBtnsDisabled), "default", { inline: true });

  // Toggle data-is-disabled attr. on buttons...
  [prevBtn, nextBtn].forEach((el) => el?.setAttribute("data-is-disabled", navBtnsDisabled));
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
