// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import updt_styleProp from "../../../baseUtilities/updt/updt_styleProp";

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function fitTextToTarget(TARGETEL) {
  if (!TARGETEL) return;

  // Setup...
  const body = document.body;
  const innerWrapper = TARGETEL.querySelector("[data-fit-text-inner]");

  // In-/Decrease font size to fit inner wrapper to target's height...
  let fontSize = parseFloat(window.getComputedStyle(innerWrapper).fontSize);
  const increaseFontSize = innerWrapper.offsetHeight < TARGETEL.offsetHeight;
  const decreaseFontSize = innerWrapper.offsetHeight > TARGETEL.offsetHeight;

  if (increaseFontSize) {
    while (innerWrapper.offsetHeight < TARGETEL.offsetHeight) {
      updt_styleProp(body, "--fontSize", `${(fontSize += 0.025)}px`);
    }
  }

  if (decreaseFontSize) {
    while (innerWrapper.offsetHeight > TARGETEL.offsetHeight) {
      updt_styleProp(body, "--fontSize", `${(fontSize -= 0.025)}px`);
    }
  }
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
