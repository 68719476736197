// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  eventStart: ["mouse moved on comp. el."],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function eh_mouseMove(EVENTDATA) {
  // Setup...
  // this.logger("event", loggerMsgs.eventStart, "event", { inline: true });

  // Stop, if comp. is in 'inactive' mode...
  if (this.state.mode === "inactive") return;

  // If mouse is inside bottom quater of comp. el., show 'view project' label...
  const compEl = this.element;
  const isInBottomQuater = EVENTDATA.clientY > compEl.getBoundingClientRect().bottom - compEl.getBoundingClientRect().height / 4;
  this.setState({ viewProjectLabelHidden: !isInBottomQuater });

  // If mouse is inside right 15% of comp. el., show 'next project' label...
  const isInRightQuater =
    EVENTDATA.clientX > compEl.getBoundingClientRect().right - compEl.getBoundingClientRect().width / (100 / 15);
  this.setState({ nextProjectLabelHidden: !isInRightQuater });

  // Determine cursor position by substr. comp. el. width and height from mouse position...
  const { cursor: cursorEl } = this.ref;
  const cursorPosition = {
    x: EVENTDATA.clientX - (compEl.getBoundingClientRect().left + cursorEl.getBoundingClientRect().width / 2),
    y: EVENTDATA.clientY - (compEl.getBoundingClientRect().top + cursorEl.getBoundingClientRect().height / 2),
  };

  // Updt. cursor state...
  this.setState({
    cursorState: {
      ...this.state.cursorState,
      position: cursorPosition,
    },
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
