// ————————————————————————————————————————————— DOC. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  error_missingARGS: ["API call", "set_activeNumBtnIndex", "argument(s) missing or invalid"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function set_activeNumBtnIndex(
  ARGS = {
    id: null,
    index: null,
  }
) {
  if (ARGS.id == undefined || !ARGS.index == undefined) return this.logger("error", loggerMsgs.error_missingARGS, "error");
  const { id, index } = ARGS;
  const { id: compId } = this.options;
  const { activeNumBtnIndex, numBtnCount } = this.state;
  this.setState({ activeNumBtnIndex: id == compId ? index : activeNumBtnIndex });

  // Determ. cursor text img. count (2nd number in fraction) by using cursor el.
  // data-init-img-count attr. as fallback in case numBtnCount is undefined...
  const cursor = this.ref.cursor;
  const initImgCount = cursor.dataset.initImgCount;
  const imgCountToUse = numBtnCount || initImgCount;

  // Use to updt. cursor state (updt. first number in cursor fraction text)...
  this.setState({ cursorState: { ...this.state.cursorState, text: `${index + 1}/${imgCountToUse}` } });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
