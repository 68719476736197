// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`»next project« label hidden: ${CHANGE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_nextProjectLabelHidden(CHANGES) {
  if (!("nextProjectLabelHidden" in CHANGES)) return;

  // Setup...
  const { nextProjectLabelHidden } = CHANGES;
  const { nextProjectLabel } = this.ref;
  this.logger("state-change", loggerMsgs.stateChange(nextProjectLabelHidden), "default", { inline: true });

  // Show/hide 'next project' label...
  nextProjectLabel?.setAttribute("data-is-hidden", nextProjectLabelHidden);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
