// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import loadComponents from "../../../../lib/jGia/jGia/src/loadComponents";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function load_globalComp() {
  // Setup...
  const { components: componentsConfig, availableComponents, globalComponentConfigs } = this.options;
  const enabledComponents = componentsConfig?.globalComponents;
  this.logger("info", ["loading global components"], "action", { inline: true });

  // Stop, if no component IDs provided...
  if (!enabledComponents || enabledComponents.length === 0) {
    this.logger("warning", ["App has no global components"], "warning", { inline: true });
    return;
  }

  // Load global components by ID...
  enabledComponents.forEach((componentData) => {
    const { id, type } = componentData;
    this.logger("info", [`Loading global component of type ${type} w/ ID ${id}`]);

    // Check if component script is available...
    const componentScript = availableComponents.find((component) => component.type === type)?.script;
    if (!componentScript) {
      this.logger("warning", [`No component script found for type: ${type}`], "warning", { inline: true });
      return;
    }

    // Check if DOM element exists...
    if (!document.getElementById(id)) {
      this.logger("warning", [`No DOM element found with ID: ${id}`], "warning", { inline: true });
      return;
    }

    // Check if component config. exists...
    const componentConfig = globalComponentConfigs[type];
    if (!componentConfig) {
      const message = [`No config. found in app config. for component type: ${type}`, `proceeding to load comp. w/out config.`];
      this.logger("warning", message, "warning", { inline: true });
    }

    // Load component...
    let component = new Object();
    component[type] = componentScript;
    loadComponents(component, null, id, componentConfig);
    //                        ^^^^
    //                        ↑ null passed for 'context' param., comp. el. is found by ID
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default load_globalComp;
