// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`cursorPosition:`, CHANGE],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_cursorPosition(CHANGES) {
  if (!("cursorPosition" in CHANGES)) return;

  // Setup...
  const { cursorPosition } = CHANGES;
  const { cursor } = this.ref;
  this.logger("state-change", loggerMsgs.stateChange(cursorPosition));

  // Updt. cursor el. position...
  cursor.style.left = `${cursorPosition.x}px`;
  cursor.style.top = `${cursorPosition.y}px`;
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
