// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`»view project« label hidden: ${CHANGE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_viewProjectLabelHidden(CHANGES) {
  if (!("viewProjectLabelHidden" in CHANGES)) return;
  if (!this.ref.viewProjectLabel || !this.validate_refEL(this.ref.viewProjectLabel)) return;

  // Setup...
  const { viewProjectLabelHidden } = CHANGES;
  const { viewProjectLabel } = this.ref;
  this.logger("state-change", loggerMsgs.stateChange(viewProjectLabelHidden), "default", { inline: true });

  // Show/hide 'view project' label...
  viewProjectLabel.setAttribute("data-is-hidden", viewProjectLabelHidden);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
