// ****************************************************************************** //
// ****************************************************************************** //

const logStyles = {
  default: "#6F58C9",
  action: "#7E78D2",
  event: "#B6B8D6",
  //////
  error: "#FF3366",
  warning: "#FFA500",
  //////
  markup: "#050505",
  trans: "#ee82ee",
};

const logStyles_typeInfo = {
  info: "color: #87CEFA; font-weight: bold;",
  init: "color: #00FA9A; font-weight: bold;",
  action: "color: #00FA9A; font-weight: bold;",
  "state-change": "color: #EE82EE; font-weight: bold;",
  event: "color: #7B68EE; font-weight: bold;",
  "eventbus call": "color: #7B68EE; font-weight: bold;",
  warning: "color: #FFA500; font-weight: bold;",
  error: "color: #FF3366; font-weight: bold;",
};

const logPadding = "padding: 1px 2px";

// ****************************************************************************** //
// ****************************************************************************** //

function log(CONTEXT = "App", SEGMENTS = [], STYLE = logStyles["default"], INLINE = false, TYPE = "info") {
  const style = STYLE === null ? logStyles["default"] : STYLE;

  ////////////////////////////////////////////
  // Output all segments inline, if enabled //
  ////////////////////////////////////////////

  if (INLINE) {
    const messageString = SEGMENTS.map((s, i) => (i === 0 ? `%c${CONTEXT} ▶ ${s}` : s));
    console.log(
      messageString.join(" ▶ ") + `%c ${TYPE}`,
      `color: #000; background-color: ${style}; ${logPadding};`,
      logStyles_typeInfo[TYPE] ? logStyles_typeInfo[TYPE] : "font-weight: bold;"
    );
    return;
  }

  /////////////////////////////////////////////////////
  // Output context + 1st message segment to console //
  /////////////////////////////////////////////////////

  console.log(
    `%c${CONTEXT} ▶ ${SEGMENTS[0]}%c ${TYPE}`,
    `color: #000; background-color: ${style}; ${logPadding};`,
    logStyles_typeInfo[TYPE] ? logStyles_typeInfo[TYPE] : "font-weight: bold;"
  );

  //////////////////////////////////////////////////////
  // Output each remaining message segment to console //
  //////////////////////////////////////////////////////

  // Remove 1st/already used segment...
  SEGMENTS.shift();

  // Stop, if no more message segments available...
  if (SEGMENTS.length === 0) return;

  // Remove message segments w/ empty strings...
  SEGMENTS = SEGMENTS.filter((s) => s);

  // Create indent (to keep console outputs vertically aligned)...
  const whiteSpace = " ";
  const indent = whiteSpace.repeat(CONTEXT.length);

  // Output segments...
  SEGMENTS.forEach((s) => {
    // If segement is string, output as such...
    if (typeof s === "string") {
      console.log(`%c${indent} ▶ ${s}`, `color: #000; background-color: ${style}; ${logPadding};`);
      return;
    }

    // If segement is object, output it w/out conversion to string (would result in "[object object]")...
    if (typeof s === "object") {
      console.log(`%c${indent} ▶`, `color: #000; background-color: ${style}; ${logPadding};`, s);
    }
  });
}

// ****************************************************************************** //
// ****************************************************************************** //

export default log;
