// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import loadComponents from "../../../../lib/jGia/jGia/src/loadComponents";

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function load_fragmentPageComp(ID) {
  // Setup //
  const { availableComponents, globalComponentConfigs } = this.options;
  this.logger("info", ["loading FragmentPage component"], "action", { inline: true });

  // Check if component script is available...
  const componentScript = availableComponents.find((comp) => comp.type === "FragmentPage")?.script;
  if (!componentScript) {
    this.logger("warning", [`No component script found for type: FragmentPage`], "warning", { inline: true });
    return;
  }

  // Check if applicable DOM element exists...
  const domEl = document.getElementById(ID);
  if (!domEl) {
    this.logger("warning", [`No DOM element found with ID ${ID}`], "warning", { inline: true });
    return;
  }

  // Check if component config. exists...
  const componentConfig = globalComponentConfigs["FragmentPage"];
  if (!componentConfig) {
    const message = [
      `No config. found in app config. for component type: FragmentPage`,
      `proceeding to load comp. w/out config.`,
    ];
    this.logger("warning", message, "warning", { inline: true });
  }

  // Load component...
  let component = new Object();
  component["FragmentPage"] = componentScript;
  loadComponents(component, null, ID, componentConfig);
  //                        ^^^^
  //                        ↑ null passed for 'context' param., comp. el. is found by ID
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default load_fragmentPageComp;
