// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————— EVENT HANDLERS ———————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

import eh_imgButton_click from "../eventHandlers/eh_imgButton_click.js";

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  initStart: ["init. imgButtons"],
  initSuccess: ["init. imgButtons", "init. success"],
  noValidDOMEl: ["init. imgButtons", "no valid DOM element provided"],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function init_imgButtons() {
  // Setup...
  const { imgButtons } = this.ref;
  this.logger("init", loggerMsgs.initStart, "action");

  // Guard...
  if (imgButtons.every((el) => !this.validate_refEL(el))) return this.cancel_featureInit(loggerMsgs.noValidDOMEl);

  // Listen to click events on buttons...
  imgButtons.forEach((el) => el?.addEventListener("click", eh_imgButton_click.bind(this, el)));

  // Conclude...
  this.logger("init", loggerMsgs.initSuccess, "success", { inline: true });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
