// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`number buttons hidden: ${CHANGE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_numBtnsHidden(CHANGES) {
  if (!("numBtnsHidden" in CHANGES)) return;

  // Setup...
  const { numBtnsHidden } = CHANGES;
  const { numberBtnWrapper } = this.ref;
  this.logger("state-change", loggerMsgs.stateChange(numBtnsHidden), "default", { inline: true });

  // Toggle data-is-inactive attr. on number button wrapper...
  numberBtnWrapper.setAttribute("data-is-inactive", numBtnsHidden);
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
