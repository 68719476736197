// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— ASSETS ———————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

const loggerMsgs = {
  stateChange: (CHANGE) => [`active item ID: ${CHANGE}`],
};

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default function stChH_activeItemID(CHANGES) {
  if (!("activeItemID" in CHANGES)) return;

  // Setup...
  const { images } = this.ref;
  const { activeItemID } = CHANGES;
  this.logger("state-change", loggerMsgs.stateChange(activeItemID), "default", { inline: true });

  // Show/hide images...
  images.forEach((img) => {
    const is_hidden = img.dataset.id != activeItemID || activeItemID === "null";
    img.setAttribute("data-is-hidden", is_hidden);
  });
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //
