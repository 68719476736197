// ****************************************************************************** //
// ****************************************************************************** //

function updt_styleProp(EL, PROP, VALUE) {
	if (!EL) {
		console.warn("No valid DOM element privided to updt_styleProp() utility");
		return false;
	}

	EL.style.setProperty(PROP, VALUE);
}

// ****************************************************************************** //
// ****************************************************************************** //

export default updt_styleProp;
