// ————————————————————————————————————————————— LIB. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————— UTIL. ————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

// ...

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

function ebh_swup_page_load(ARGS) {
  // Setup...
  const { fragmIsBeingOpened, _name } = ARGS;
  this.logger("eventbus call", [_name], "event", { inline: true });

  ///////////////////////////////////////
  // Fragment Page component unloading //
  ///////////////////////////////////////

  if (!fragmIsBeingOpened) this.unload_fragmentPageComp("fragmentPage0");
}

// ———————————————————————————————————————————————————————————————————————————————————————————————— //
// ———————————————————————————————————————————————————————————————————————————————————————————————— //

export default ebh_swup_page_load;
